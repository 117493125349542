<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"> Home </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Checkout
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="all-product-grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-7">
              <div id="checkout_wizard" class="checkout accordion left-chck145">
                <div class="checkout-step">
                  <div class="checkout-card" id="headingTwo">
                    <span class="checkout-step-number">1</span>
                    <h4 class="checkout-step-title">
                      <button
                        class="wizard-btn collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Delivery Address
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse in show"
                    aria-labelledby="headingTwo"
                  >
                    <div class="checkout-step-body">
                      <div class="checout-address-step">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="address-fieldset">
                              <form @submit.prevent="MakePayment">
                                <div class="row">
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Name*</label>
                                      <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Name"
                                        class="form-control input-md"
                                        v-model="form.first_name"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Email Address*</label
                                      >
                                      <input
                                        id="email1"
                                        name="email1"
                                        type="text"
                                        placeholder="Email Address"
                                        class="form-control input-md"
                                        v-model="form.email"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Flat / House / Office No.*</label
                                      >
                                      <input
                                        id="flat"
                                        name="flat"
                                        type="text"
                                        placeholder="Address"
                                        class="form-control input-md"
                                        v-model="form.add_1"
                                        required=""
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Street / Society / Office Name*</label
                                      >
                                      <input
                                        id="street"
                                        name="street"
                                        type="text"
                                        placeholder="Street Address"
                                        class="form-control input-md"
                                        v-model="form.add_2"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Landmark</label
                                      >
                                      <input
                                        id="street"
                                        name="street"
                                        type="text"
                                        placeholder="Landmark"
                                        class="form-control input-md"
                                        v-model="form.add_3"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Pincode*</label
                                      >
                                      <input
                                        id="pincode"
                                        name="pincode"
                                        type="text"
                                        placeholder="Pincode"
                                        class="form-control input-md"
                                        v-model="form.pincode"
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Locality*</label
                                      >
                                      <select
                                        class="form-control lgn_input"
                                        v-model="form.city_id"
                                        required
                                      >
                                        <option value="">Select City</option>
                                        <option
                                          v-for="(city, c) in cities"
                                          :key="`city${c}`"
                                          :value="city.id"
                                        >
                                          {{ city.name }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                    <ul class="radio--group-inline-container_1">
                                      <li>
                                        <div class="radio-item_1">
                                          <input
                                            id="cashondelivery1"
                                            value="cashondelivery"
                                            name="paymentmethod"
                                            type="radio"
                                            data-minimum="50.0"
                                            v-model="order.payment_mode"
                                          />
                                          <label
                                            for="cashondelivery1"
                                            class="radio-label_1"
                                            >Cash on Delivery</label
                                          >
                                        </div>
                                      </li>
                                      <li>
                                        <div class="radio-item_1">
                                          <input
                                            id="card1"
                                            value="online"
                                            name="paymentmethod"
                                            type="radio"
                                            data-minimum="50.0"
                                            v-model="order.payment_mode"
                                          />
                                          <label
                                            for="card1"
                                            class="radio-label_1"
                                            >Online Payment</label
                                          >
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                      <div class="address-btns">
                                        <button
                                          v-if="!isLoading"
                                          class="save-btn14 hover-btn"
                                          @submit="MakePayment"
                                        >
                                          Place Order
                                        </button>
                                        <b-button
                                          v-else
                                          class="save-btn14 hover-btn"
                                          style="background-color: #f55d2c"
                                          disabled
                                        >
                                          <b-spinner
                                            small
                                            type="grow"
                                          ></b-spinner>
                                          Processing...
                                        </b-button>
                                      </div>
                                      <span v-if="done">Updated</span>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-5">
              <div class="pdpt-bg mt-0">
                <div class="pdpt-title">
                  <h4>Order Summary</h4>
                </div>
                <div class="total-checkout-group">
                  <div class="cart-total-dil">
                    <h6>{{ order.store.name }}</h6>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <b-table-simple
                      hover
                      small
                      caption-top
                      responsive
                      style="height: 300px"
                    >
                      <b-thead>
                        <b-tr>
                          <b-th>Product Name</b-th>
                          <b-th>Quantity</b-th>
                          <b-th>Price</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr
                          v-for="(detail, o) in order.order_details"
                          :key="`detail${o}`"
                        >
                          <b-td>{{ detail.product.name.substr(0, 20) }}</b-td>
                          <b-td>{{ detail.quantity | twoDecimal }}</b-td>
                          <b-td>{{
                            detail.order_detail_cost | twoDecimal
                          }}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                    <!-- <h6>
                      {{ detail.product.name.substr(0, 20) }} |
                      {{ detail.product_pack }} | Qty: {{ detail.quantity }}
                    </h6>
                    <span
                      >₹
                      {{
                        parseFloat(detail.order_detail_cost) | twoDecimal
                      }}</span
                    > -->
                  </div>
                  <div class="main-total-cart">
                    <h2>Sub Total</h2>
                    <span>₹ {{ subTotal | twoDecimal }}</span>
                  </div>
                  <br />
                  <a href="#" data-toggle="modal" data-target="#couponModal"
                    >Add Coupon</a
                  >
                  <div class="cart-total-dil pt-3">
                    <h6>Coupon</h6>
                    <span>- ₹ {{ coupon_amount | twoDecimal }}</span>
                  </div>
                  <div class="main-total-cart">
                    <h2>Taxable Amount</h2>
                    <span>₹ {{ taxableAmount | twoDecimal }}</span>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <h6>Tax</h6>
                    <span>₹ {{ tax | twoDecimal }}</span>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <h6>Packaging</h6>
                    <span>₹ {{ order.packaging_amount | twoDecimal }}</span>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <h6>
                      <input type="checkbox" v-model="isWalletBalanceUsed" />
                      Use Wallet Amount (₹ {{ todayBalance | twoDecimal }})
                    </h6>
                    <span>- ₹ {{ walletAmount | twoDecimal }}</span>
                  </div>
                  <div class="cart-total-dil pt-3">
                    <h6>Delivery Charges</h6>
                    <span>₹ {{ order.delivery_amount | twoDecimal }}</span>
                  </div>
                  <br />
                  <a href="#" data-toggle="modal" data-target="#tipModal"
                    >Add Tip</a
                  >
                  <div class="cart-total-dil pt-3">
                    <h6>Tip</h6>
                    <span>₹ {{ tip | twoDecimal }}</span>
                  </div>
                </div>
                <div class="main-total-cart">
                  <h2>Total Payable</h2>
                  <span>₹ {{ total_payable | twoDecimal }}</span>
                </div>
                <div class="total-checkout-group">
                  <div class="cart-total-dil pt-3">
                    <h6>Total Cashback</h6>
                    <span>₹ {{ order.total_cashback | twoDecimal }}</span>
                  </div>
                </div>
                <div class="payment-secure">
                  <i class="uil uil-padlock"></i>Secure checkout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->

    <!-- Coupon Modal -->
    <div
      class="modal fade"
      id="couponModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="couponModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="couponModalLabel">Coupons</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="coupons"
              v-for="(coupon, c) in coupons"
              :key="`coupon${c}`"
            >
              {{ coupon.name }}
              <br />
              <div class="row">
                <div class="col-md-8">
                  <span v-if="coupon.type == 'Flat'">
                    Get {{ coupon.type }} ₹ {{ coupon.value }} on min. order
                    value of ₹ {{ coupon.min_order_value }}
                  </span>
                  <span v-else>
                    Get {{ coupon.value }} % on min. order value of ₹
                    {{ coupon.min_order_value }}
                  </span>
                </div>
                <div class="col-md-4 text-right">
                  <button
                    v-if="subTotal >= coupon.min_order_value"
                    class="btn btn-link"
                    href="#"
                    @click="applyCoupon(coupon)"
                    data-dismiss="modal"
                  >
                    APPLY
                  </button>
                  <span v-else
                    >Add more (₹{{
                      (coupon.min_order_value - subTotal) | twoDecimal
                    }})</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tip Modal -->
    <div
      class="modal fade"
      id="tipModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="tipModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="tipModalLabel">Add Tip</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <button
              class="btn btn-primary"
              @click="updateTip(20)"
              data-dismiss="modal"
            >
              20</button
            >&nbsp;&nbsp;
            <button
              class="btn btn-primary"
              @click="updateTip(30)"
              data-dismiss="modal"
            >
              30</button
            >&nbsp;&nbsp;
            <button
              class="btn btn-primary"
              @click="updateTip(50)"
              data-dismiss="modal"
            >
              50</button
            >&nbsp;&nbsp; <br /><br />
            <input
              type="number"
              class="form-control"
              placeholder="Add amount"
              v-model="tip"
            />
            <br />
            <button class="btn btn-primary" data-dismiss="modal">Apply</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="statusSuccessModal"
      align="center"
      @hide="onOk"
      @ok="onOk"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      no-close-on-outer-click
    >
      <template #modal-title>
        <span style="color: green"> Order Place SuccessFully </span>
      </template>
      <div>
        <img src="../../assets/success.gif" width="350" alt="" />
      </div>
      <p class="my-4" style="font-size: 20px">Order Successfully Placed</p>
      <b-button
        class="mt-3"
        block
        @click="onOk"
        style="background-color: #f55d2c"
        >Ok</b-button
      >
    </b-modal>
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      form: {
        add_1: "",
        add_2: "",
        add_3: "",
        city: "",
        pincode: "",
      },
      done: false,
      coupon_id: "",
      coupon_amount: 0,
      tip: 0,
      walletBalance: 0,
      todayBalance: 0,
      isLoading: false,
      isWalletBalanceUsed: false,
      is_approved_by_hotelier: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      cities: "city/getCities",
      order: "order/getOrder",
      medialURL: "settings/getMediaUrl",
    }),
    subTotal() {
      return parseFloat(this.order.order_cost);
    },
    taxableAmount() {
      return parseFloat(this.subTotal - this.coupon_amount);
    },
    tax() {
      return parseFloat(this.taxableAmount * this.order.tax_percent) / 100;
    },
    walletAmount() {
      if (this.isWalletBalanceUsed) {
        if (
          this.todayBalance <
          this.taxableAmount + this.tax + this.order.packaging_amount
        )
          return this.todayBalance;
        else return this.taxableAmount + this.tax + this.order.packaging_amount;
      }
      return 0;
    },
    total_payable() {
      return parseFloat(
        this.taxableAmount +
          this.tax +
          this.order.packaging_amount -
          this.walletAmount +
          parseFloat(this.tip || 0) +
          parseFloat(this.order.delivery_amount)
      ).toFixed(2);
    },
  },
  asyncComputed: {
    async coupons() {
      let coupons = [];
      if (this.order.store.id) {
        let couponsData = await axios.get("company_coupons", {
          headers: {
            "company-id": this.order.store.id,
          },
        });
        coupons = couponsData.data.data;
      }
      return coupons;
    },
  },
  mounted() {
    this.form = this.user;
    this.form.city = this.form.city || "";
    if (localStorage.getItem("transactionId") != null) {
      console.log("Payment Status Called");
      this.order.payment_mode = "online";
      this.PaymentStatus();
    }
    this.getWalletBalance();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState == 'visible') {
        this.PaymentStatus();
      }
    },
    async getWalletBalance() {
      let todayBalance = 0;
      let user_wallets = await axios.get(
        `/user_wallets?userId=${this.user.id}`
      );
      user_wallets = user_wallets.data.data;
      let now = moment().format("DD-MM-YYYY");
      user_wallets.forEach((wallet, i) => {
        let expiry = "";
        let next = "";
        expiry = moment(wallet.created_at)
          .add(wallet.no_of_days, "days")
          .format("DD-MM-YYYY");
        if (i < user_wallets.length - 1)
          next = moment(user_wallets[i + 1].created_at)
            .add(user_wallets[i + 1].no_of_days, "days")
            .format("DD-MM-YYYY");
        else next = moment().format("DD-MM-YYYY");
        if (expiry >= next) {
          this.walletBalance = this.walletBalance + parseFloat(wallet.amount);
        }
        if (expiry >= now)
          todayBalance = todayBalance + parseFloat(wallet.amount);
        if (wallet.source == "Amount Used") {
          this.walletBalance = this.walletBalance + parseFloat(wallet.amount);
        }
      });
      if (this.walletBalance < todayBalance)
        this.todayBalance = this.walletBalance;
      else this.todayBalance = todayBalance;
    },
    onOk() {
      this.$router.push("/");
    },
    async MakePayment() {
      console.log("payment", this.order.payment_mode);
      if (this.order.payment_mode == "cashondelivery") {
        await this.updateUser();
      } else {
        let payload = {
          with_gst_included: this.total_payable,
          // with_gst_included: 1,
          phone: "9372624008",
        };
        let response = await axios.post("/orders/make_payment", payload);
        this.paymentUrl =
          response.data.data.data.instrumentResponse.redirectInfo.url;
        localStorage.setItem(
          "transactionId",
          response.data.data.data.merchantTransactionId
        );
        window.location.href = this.paymentUrl;
      }
    },
    async PaymentStatus() {
      let payload = {
        transactionId: localStorage.getItem("transactionId"),
      };
      let response = await axios.post("/orders/payment-status", payload);
      if (response.data.data.code == "PAYMENT_SUCCESS") {
        this.order.payment_status = response.data.data.code;
        this.order.transaction_id =
          response.data.data.data.merchantTransactionId;
        this.order.payment_json = response.data.data.data;
        this.order.payment_mode = "online";
        await this.updateUser();
        localStorage.removeItem("transactionId");
        // this.$bvModal.show("statusSuccessModal");
      }else if( response.data.data.code =='PAYMENT_PENDING' || response.data.data.code == 'PAYMENT_ERROR'){
        this.$router.push("/payment?transactionId=" + localStorage.getItem("transactionId"));
      }
    },
    async updateUser() {
      console.log("called UpdateUser");

      this.done = false;
      await axios.patch(`/users/${this.user.id}`, this.form);

      if (this.order.payment_mode == "" || this.order.payment_mode == null)
        alert("Please select payment mode");
      else {
        this.saveOrder();
        this.done = true;
      }
    },
    removeOrderDetailFrontEnd(orderDetail) {
      this.$store.dispatch("order/removeOrderDetail", orderDetail);
    },

    async saveOrder() {
      console.log("called SaveOrder");

      this.isLoading = true;
      let finalOrder = Object.assign(this.order);
      finalOrder.user_id = this.user.id;
      finalOrder.details = "-";
      finalOrder.coupon_id = this.coupon_id;
      finalOrder.coupon_amount = this.coupon_amount;
      finalOrder.tax_amount = this.tax;
      finalOrder.wallet_amount_used = this.walletAmount;
      finalOrder.tip_amount = this.tip;
      finalOrder.total_amount = this.total_payable;
      finalOrder.is_approved_by_hotelier = 0;
      axios.post("/orders", finalOrder, {
        headers: {
          "company-id": finalOrder.store.id,
        },
      });
      // order_response = order_response.data;
      this.$bvModal.show("statusSuccessModal");
      this.isLoading = false;
      this.$store.dispatch("order/emptyOrder");

      // if (order_response.order.payment_mode == "online") {
      //   window.location.href =
      //     order_response.data.data.instrumentResponse.redirectInfo.url;
      // } else {
      //   // window.location.href = "appointment-booked-successfully.php";
      // }
      // if (this.order.payment_mode == "card")
      //   this.$router.push("/payment?orderId=" + savedOrder.id);
      // else this.$router.push("/thankyou");
    },
    applyCoupon(coupon) {
      this.coupon_id = coupon.id;
      if (coupon.type == "Flat") this.coupon_amount = parseFloat(coupon.value);
      else
        this.coupon_amount = parseFloat(
          (this.order.order_cost * coupon.value) / 100
        );
    },
    updateTip(tip) {
      this.tip = parseFloat(tip);
    },
  },
};
</script>
<style scoped>
.coupons {
  margin-top: 10px;
}

.coupons:first-child {
  margin-top: 0;
}

.modal#statusSuccessModal .modal-content,
.modal#statusErrorsModal .modal-content {
  border-radius: 30px;
}

.modal#statusSuccessModal .modal-content svg,
.modal#statusErrorsModal .modal-content svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.modal#statusSuccessModal .modal-content .path,
.modal#statusErrorsModal .modal-content .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.modal#statusSuccessModal .modal-content .path.circle,
.modal#statusErrorsModal .modal-content .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.modal#statusSuccessModal .modal-content .path.line,
.modal#statusErrorsModal .modal-content .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.95s 0.35s ease-in-out forwards;
  animation: dash 0.95s 0.35s ease-in-out forwards;
}

.modal#statusSuccessModal .modal-content .path.check,
.modal#statusErrorsModal .modal-content .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.95s 0.35s ease-in-out forwards;
  animation: dash-check 0.95s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.box00 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
