<template>
  <section>
    <div class="wrapper">
      <!-- <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-10">
            <div class="payment-status">
              <div v-if="loading">
                <h2>Processing your payment, please wait...</h2>
              </div>
              <div v-else-if="paymentStatus === 'success'">
                <h1 class="text-success">Payment Successful</h1>
                <p>
                  Thank you for your payment! Your order has been confirmed.
                </p>
                <p><strong>Order ID:</strong> {{ paymentDetails.orderId }}</p>
                <p>
                  <strong>Transaction ID:</strong> {{ paymentDetails.txnId }}
                </p>
                <p>
                  <strong>Amount Paid:</strong> ₹{{ paymentDetails.amount }}
                </p>
              </div>

              <div v-else>
                <h1 class="text-danger">Payment Failed</h1>
                <p>Unfortunately, your payment could not be processed.</p>
                <p>
                  Please try again or contact support if the issue persists.
                </p>
              </div>
              <router-link to="/" class="btn btn-primary mt-3">
                Return to Home
              </router-link>
            </div>
          </div>
        </div>
      </div> -->
      <div class="container">
        <div
          class="row justify-content-center"
          v-if="paymentStatus === 'PAYMENT_SUCCESS'"
        >
          <div class="col-lg-8 col-md-10">
            <div class="payment-status">
              <h1 class="text-success">payment was successfully done!</h1>
              <p>Thank you for your payment! Your order has been confirmed.</p>
              <router-link to="/" class="btn btn-primary mt-3">
                Return to Home
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="col-lg-8 col-md-10">
            <div class="payment-status">
              <h1 class="text-danger">Payment Failed</h1>
              <p>Please try again or contact support if the issue persists.</p>
              <router-link to="/checkout" class="btn btn-primary mt-3" >
                Go Back to Checkout
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  // props: {
  //   formData: Object,
  // },
  data() {
    return {
      loading: true,
      paymentStatus: null,
      paymentDetails: {},
      errors: {},
      isLoading: true,

      formData: {
        transactionId: this.$route.query.transactionId,
      },
    };
  },
  mounted() {
    this.checkPaymentStatus();
  },
  methods: {
    async checkPaymentStatus() {
      try {
        const response = await axios.post(
          `/orders/payment-status`,
          this.formData
        );
        this.paymentDetails = response.data.data;
        this.paymentStatus = response.data.data.code;
        this.loading = false;
        if(localStorage.getItem("transactionId") != null){
          localStorage.removeItem("transactionId");
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          this.paymentStatus = "failure";
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.payment-status {
  text-align: center;
  padding: 50px 20px;
}
.text-success {
  color: #28a745;
}
.text-danger {
  color: #dc3545;
}
</style>
