<template>
  <div class="product-item">
    <router-link :to="`/categories/${store.category_id}/stores/${store.id}`" class="product-img">
      <img :src="store.logo_path ? `${medialURL}${store.logo_path}`  : '/images/product/img-1.jpg'" alt="">
      <div class="product-absolute-options">
        <span class="offer-badge-1">NEW</span>
      </div>
    </router-link>
    <div class="product-text-dt">
      <h4>{{ store.name }}</h4>
    </div>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex'

export default {
  props: ['store'],
  computed: {
    ...mapGetters({
      medialURL: 'settings/getMediaUrl'
    })
  },
}
</script>